import React from "react";

export default ({ title, description, url, author, date, minutes }) => {
    return <div class="columns">
        <div class="column">
        <a href={url}>
            <div class="card">
                <div class="card-content">
                    <div class="content">
                        <p class="title is-4">{title}</p>
                        <p>{description}</p>
                        <p class="subtitle is-6">{author} - {date} - {minutes} min read</p>
                    </div>
                </div>
            </div>
        </a>
        </div>
    </div>
};
