import React from "react";
import Connecteur from '../../components/Connecteur';
import BlogCard from "../../components/BlogCard"
import InsLogo from '../../media/connecteurs/ins-logo.png';


export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "ins",
      title: "Identifiant national de santé",
      description: "Un connecteur facile à mettre en place pour utiliser le téléservice INSi.",
      articles:
      <section class="alt-section">
          <div class="container">
          <h1 class="title">En apprendre davantage :</h1>
            <div class="columns">
              <div class="column is-7">
                    <BlogCard 
                     title= "Déployer l’INS, les grandes étapes"
                     url= "/blog/mettre-en-place-ins"
                     minutes= "2"
                     date= "April 30"
                     author= "Anthony Dubois"
                     description= "Un guide pratique pour comprendre les grandes étapes nécessaires à la mise en place de l'INS dans votre logiciel de santé."
                    />
              </div>
            </div>
          </div>
        </section>,
      content:
      <div>
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                    <h2>L'identifiant national de santé</h2>
                    <p>L'INS est un téléservice mis en place au niveau national pour identifier de manière unique et pérenne un patient en France.</p>
                    <h2 class="subtitle">Que propose Akimed ?</h2>
                    <p>Une assistance de A à Z sur la mise en place de l'INS (de l'inscription au CNDA à l'intégration complète dans vos logiciels).</p>
                    <h3>1. Accompagnement administratif</h3>
                    <p>A force de le faire pour nos clients, nous sommes devenus des experts des formulaires à remplir pour vous aider à aller plus vite dans le mise en place de ce service.</p>
                    <p>Vous ne comprenez pas les liens entre le Sesam Vital, le CNDA et l'ANS ? On vous explique et on s'occupe de tout.</p>
                    <h3>2. Mise en place technique</h3>
                    <p>Grâce à notre plateforme Akimed Connect, vous pourrez accéder au téléservice INSi grâce à une simple API REST qui respecte les standards d'interopérabilité.</p>
                    <p>Vos équipes se concentrent sur leur métier et on les accompagne sur l'utilisation de l'API et des tests à réaliser pour la certification.</p>
                    <p><strong>Exemple: rechercher l'INS d'un patient à partir de ses traits d'identité</strong></p>
                    <pre>
                        GET https://api.akimed.io/ins/search/ <br /><br />
                        &#123; <br />
                        &nbsp;&nbsp;"name": &#123; <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;"family": "D'ARTAGNAN DE L'HERAULT", <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;"given": "Pierre-Alain" <br />
                        &nbsp;&nbsp;&#125;, <br />
                        &nbsp;&nbsp;"gender": "male", <br />
                        &nbsp;&nbsp;"birthDate": "2001-06-17" <br />
                        &#125;
                    </pre>
                    <h3>3. Homologation</h3>
                    <p>Vous avez fini d'adapter votre frontend et votre base de données, c'est parti pour l'homologation avec le CNDA. Taux de réussite : 100%.</p>
                    <h2>Ce que disent nos clients</h2>
                </div>
            </div>
            <div class="column alt-column is-2 is-offset-1">
                <figure class="image">
                  <img src={ InsLogo } alt="Logo INS" />
                </figure>
                <a class="button is-danger" href="../../contact">On discute ?</a>
            </div>
        </div>
        <div class="columns">
        <div class="column is-3">
            <div class="box">
                <p class="block">Le service que vous proposez est un pur bonheur à intégrer tellement le tout est simple :)</p>
                <p class="has-text-weight-bold block">Ronan @ Pixaid</p>
            </div>
        </div>
        <div class="column is-3">
            <div class="box">
                <p class="block">En plus de leur service API facile à mettre en place, l'équipe est sympa. On continue avec eux pour Pro Santé Connect.</p>
                <p class="has-text-weight-bold block">André @ Urgences chrono</p>
            </div>
        </div>
    </div>
      </div>

    };
  }

  render() {
    return (
        <Connecteur 
            content={this.state.content} 
            activePage={this.state.page} 
            title={this.state.title}
            description={this.state.description}
            articles={this.state.articles}
        />
    );
  }
}
